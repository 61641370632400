import { isEmpty } from 'lodash';
import {
  predictionOriginEnum,
  PredictionTypeEnum,
} from '../../App/contexts/InputTabsContext';
import axiosInstance from '../../services/AxiosPublicInstance';
import { createRequestQuery } from '../../services/axiosFiles/sagaCommon';

export const predictionTypes = {
  NONE: '',
  STREET: 'rue',
  MUNICIPALITY: 'Commune',
  LOCALITY: 'Lieu-dit',
};

const BASE_URL = process.env.REACT_APP_ADDRESS_SERVER_URL;

export default (() => {
  // *********************************************
  // ***** getLocationByLatLng result parser *****
  // *************** Not exported ****************
  // *********************************************
  const addressServerLocationByLatlngParser = (
    lat: number,
    lng: number,
    data: IAddrServFeature[]
  ): GeolocPointInfo => {
    try {
      const properties = data[0].properties;

      const parsedData: GeolocPointInfo = {
        city: properties.city,
        houseNumber: properties.housenumber ?? null,
        streetName: properties.street ?? null,
        postalCode: properties.postcode,
        inseeCode: properties.citycode,
        address: properties.label,
        department: properties.citycode?.substring(0, 2),
        // use clic lat/lng and not addrServ lat/lng response
        coordinates: [lat, lng],
      };
      return parsedData;
    } catch (error: any) {
      throw new Error(error.message ?? 'addressServer parser error');
    }
  };

  const getLocationByLatLngAsync = async (
    lat: number,
    lng: number
  ): Promise<GeolocPointInfo | null> => {
    try {
      const params = {
        lat: lat,
        lon: lng,
        type: 'housenumber',
      };

      const result = await axiosInstance().get(`${BASE_URL}/reverse/`, {
        params,
        timeout: 5000,
      });

      if (!isEmpty(result.data?.features)) {
        const parsedData: GeolocPointInfo = addressServerLocationByLatlngParser(
          lat,
          lng,
          result.data.features
        );

        return parsedData;
      } else {
        return null;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  // *********************************************
  // **** Get predictions from server address ****
  // ***************** Exported ******************
  // *********************************************
  const getPredictions = (
    search: string,
    options: ServerAddressAutocompleteOptions
  ) => {
    const queries = createRequestQuery({ ...options, q: search });

    return new Promise<PredictionBase>((resolve, reject) => {
      const predictionBase: PredictionBase = {
        origin: predictionOriginEnum.ADDRESS_SERVER,
        data: [],
      };

      return axiosInstance()
        .get(`${BASE_URL}/search/${queries}`)
        .then((response) => {
          const results: IAddrServFeature[] = response.data.features;
          const predictions: Prediction[] = results.map((p) => {
            const properties = p.properties;
            let type: PredictionTypeEnum;

            switch (p?.properties?.type) {
              case 'street':
              case 'housenumber':
                type = PredictionTypeEnum.STREET;
                break;
              case 'locality':
                type = PredictionTypeEnum.LOCALITY;
                break;
              case 'municipality':
              default:
                type = PredictionTypeEnum.MUNICIPALITY;
                break;
            }
            const parsed = {
              id: properties.id,
              city: properties.city,
              label: properties.label,
              type: type,
              coordinates: [p.geometry.coordinates[1], p.geometry.coordinates[0]],
            };

            return parsed;
          });

          predictionBase.data = predictions;
          return resolve(predictionBase);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  return { getPredictions, getLocationByLatLngAsync };
})();
