import * as protomaps from 'protomaps';
import { memo, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useAppSelector } from '../../../App/store';
import { getLayerEnvState } from '../../envs/envSlice';
import { getMapState } from '../../map/mapSlice';
import { MyPaintSymbolizerPluEnv } from './methods';
import { PolygonLabelSymbolizer } from './symbolizer/symbolizer';

function PmTilesPluEnv() {
  const map = useMap();
  const { envLayers } = useAppSelector(getLayerEnvState);
  const { geolocDatas } = useAppSelector(getMapState);

  // Define the custom order
  const customOrder = [
    '2_500m',
    '2_300m',
    '2_QPV2015',
    '2_QPV2015modif',
    '2_QPV2024',
  ];

  // Create a comparison function based on the custom order
  const customSort = (a: any, b: any) => {
    const indexA = customOrder.indexOf(a.layer);
    const indexB = customOrder.indexOf(b.layer);

    if (indexA !== -1 || indexB !== -1) return indexA - indexB;
    return a.layer.localeCompare(b.layer);
  };

  const resetLayer = () => {
    map.eachLayer((l: any) => {
      if (l.options.id === 'plu_env') {
        map.removeLayer(l);
      }
    });
  };

  useEffect(() => {
    if (geolocDatas?.inseeCode && envLayers.result) {
      // reset layer if a city is already displayed
      resetLayer();

      envLayers.result.map((envLayer) => {
        if (envLayer.pmtilesFileUrl && envLayer.displayed) {
          const p = new protomaps.PMTiles(envLayer.pmtilesFileUrl);

          p.getMetadata().then((m: any) => {
            m.tilestats.layers.sort(customSort).map(
              (elt: any) => {
                const layer = protomaps.leafletLayer({
                  paint_rules: [
                    {
                      dataLayer: elt.layer,
                      symbolizer: new MyPaintSymbolizerPluEnv(),
                      minzoom: 0,
                      maxzoom: 22,
                    },
                  ],
                  label_rules: [
                    {
                      dataLayer: elt.layer,
                      symbolizer: new PolygonLabelSymbolizer({
                        label_props: (zoom: any, feature: any) => {
                          feature.props.libelle = `Quartier Prioritaire - ${feature.props.cat_child}`;
                          return ['libelle'];
                        },
                        fill: 'white',
                        width: 1.5,
                        stroke: 'red',
                        font: '600 14px sans-serif',
                        lineHeight: 1.2,
                      }),
                      minzoom: 15,
                      maxzoom: 22,
                    },
                  ],

                  attribution: '',
                  url: p.source.getKey(),
                });

                layer.options.id = 'plu_env';
                layer.options.zIndex = 3;
                layer.options.opacity = 0.7;
                layer.addTo(map);
              },
              (err: any) => console.log('err', err)
            );
          });
        }
      });
    } else {
      // reset layer if no insee code
      resetLayer();
    }
  }, [geolocDatas?.inseeCode, envLayers]);

  return null;
}

export default memo(PmTilesPluEnv);
