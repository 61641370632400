import { getAppState } from '../../../App/appSlice';
import { predictionOriginEnum } from '../../../App/contexts/InputTabsContext';
import { useAppSelector } from '../../../App/store';
import SubscriptionCard from '../../../features/company/subscription/subscriptionCard';
import useCompany from '../../../features/company/useCompany';
import useSubscription from '../../../features/company/useSubscription';
import IndividualCircularLoader from '../../../features/loaders/individualCircularLoader';
import SwitchDomTom from '../../../features/switchDomTom';
import './SubscriptionTab.scss';

export default function SubscriptionTab(): JSX.Element {
  useCompany({ loadCompany: true });
  const { autocompleteType } = useAppSelector(getAppState);
  const subscription = useSubscription({ loadCities: true, loadDepartments: true });

  return (
    <div className="tab-subscription">
      <h1>Mon abonnement</h1>
      <div className="tab-body">
        {subscription &&
        subscription.cities.result &&
        subscription.departments.result ? (
          <>
            <SubscriptionCard subscription={subscription} />
            {autocompleteType === predictionOriginEnum.GOOGLE &&
              subscription?.hasFullMapAccess && <SwitchDomTom />}
          </>
        ) : (
          <IndividualCircularLoader size={200} />
        )}
      </div>
    </div>
  );
}
