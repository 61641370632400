import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { ChangeEvent } from 'react';
import { predictionOriginEnum } from '../../../App/contexts/InputTabsContext';
import styles from './formComponents.module.scss';

interface IRadioBtnCustomProps {
  disabled?: boolean;
  className?: string;
  label?: string;
  value: predictionOriginEnum;
  radioBtnValues: IValueDisplayType[];
  onChange: (e: ChangeEvent<HTMLInputElement>, value: string) => void;
}
function RadioBtnCustom({
  disabled,
  className,
  label,
  radioBtnValues,
  value,
  onChange,
}: IRadioBtnCustomProps) {
  return (
    <FormControl
      className={[styles.radioGroupContainer, className].join(' ')}
      disabled={disabled}
    >
      {label && (
        <FormLabel id="mailshotModelTypeLabel" className={styles.groupLabel}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        value={value ?? ''}
        onChange={onChange}
        className={[styles.radioGroup].join(' ')}
        aria-labelledby="mailshotModelTypeLabel"
        row
      >
        {radioBtnValues.map((m) => (
          <FormControlLabel
            className={styles.radioLabel}
            key={nanoid()}
            value={m.value}
            control={<Radio />}
            label={m.display}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioBtnCustom;
