import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIStatus } from '../services/axiosFiles/apiTypes';
import SessionStorageService from '../services/SessionStorageService';
import {
  fetchContactRolesThunk,
  fetchPlotStudyStatusesThunk,
  fetchProcedureTypesThunk,
} from './appThunks';
import { predictionOriginEnum } from './contexts/InputTabsContext';
import { RootState } from './store';

export enum GoogleSearchContryEnum {
  FRANCE = 'France',
  DOM_TOM = 'DomTom',
}

const initialState: AppState = {
  dashboardTab: 'directory',
  accountTab: 'abonnement',
  accountFaqCurrentSlide: 0,
  autocompleteType: predictionOriginEnum.OSM,
  googleSearchType: GoogleSearchContryEnum.FRANCE,
  activateFavorite: false,
  plotStudyStatuses: { apiStatus: APIStatus.IDLE, result: null },
  contactRoles: { apiStatus: APIStatus.IDLE, result: null },
  procedureTypes: { apiStatus: APIStatus.IDLE, result: null },
  sectorActions: null,
  reloadAltimetry: 'reload',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    init: (state) => {
      state.dashboardTab = SessionStorageService.getDashboardTab() ?? 'directory';
      state.accountTab = SessionStorageService.getAccountTab() ?? 'abonnement';
      state.googleSearchType = SessionStorageService.getGoogleCountryChoice();
    },
    setDashboardTab: (state, action: PayloadAction<DashboardTab>) => {
      state.dashboardTab = action.payload;
      SessionStorageService.setDashboardTab(action.payload);
    },
    setAccountTab: (state, action: PayloadAction<AccountTab>) => {
      state.accountTab = action.payload;
      SessionStorageService.setAccountTab(action.payload);
    },
    setAutocompleteType: (state, action: PayloadAction<predictionOriginEnum>) => {
      state.autocompleteType = action.payload;
    },
    setGoogleCountryType: (state, action: PayloadAction<GoogleSearchContryEnum>) => {
      state.googleSearchType = action.payload;
      SessionStorageService.setGoogleCountryChoice(action.payload);
    },
    setReloadAltimetry: (state, action: PayloadAction<'success' | 'reload'>) => {
      state.reloadAltimetry = action.payload;
    },
    setAccountFaqCurrentSlide: (state, action: PayloadAction<number>) => {
      state.accountFaqCurrentSlide = action.payload;
    },
    setAccountFaqMailshotSlides: (state) => {
      state.accountTab = 'faq';
      state.accountFaqCurrentSlide = 7;
    },
  },
  extraReducers(builder) {
    builder
      //fetch Plot studies
      .addCase(fetchPlotStudyStatusesThunk.pending, (state) => {
        state.plotStudyStatuses.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchPlotStudyStatusesThunk.fulfilled,
        (state, action: PayloadAction<StudyStatuses>) => {
          state.plotStudyStatuses.apiStatus = APIStatus.FULFILLED;
          state.plotStudyStatuses.result = action.payload;
        }
      )
      .addCase(
        fetchPlotStudyStatusesThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.plotStudyStatuses.apiStatus = APIStatus.REJECTED;
          state.plotStudyStatuses.result = [];
          state.plotStudyStatuses.error = action.payload;
        }
      )
      // fetch Contact Roles
      .addCase(fetchContactRolesThunk.pending, (state) => {
        state.contactRoles.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchContactRolesThunk.fulfilled,
        (state, action: PayloadAction<ContactRoles>) => {
          state.contactRoles.apiStatus = APIStatus.FULFILLED;
          state.contactRoles.result = action.payload;
        }
      )
      .addCase(
        fetchContactRolesThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.contactRoles.apiStatus = APIStatus.REJECTED;
          state.contactRoles.result = [];
          state.contactRoles.error = action.payload;
        }
      )
      // fetch Contact Roles
      .addCase(fetchProcedureTypesThunk.pending, (state) => {
        state.procedureTypes.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchProcedureTypesThunk.fulfilled,
        (state, action: PayloadAction<ProcedureTypes>) => {
          state.procedureTypes.apiStatus = APIStatus.FULFILLED;
          state.procedureTypes.result = action.payload;
        }
      )
      .addCase(
        fetchProcedureTypesThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.procedureTypes.apiStatus = APIStatus.REJECTED;
          state.procedureTypes.result = [];
          state.procedureTypes.error = action.payload;
        }
      );
  },
});

// used for resete redux store (logout)
export const resetAppAction = createAction('app/resetAll');

export default appSlice.reducer;
export const getAppState = (state: RootState) => state.app;
export const appActions = appSlice.actions;
