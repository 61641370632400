import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { delay } from 'lodash';
import { memo } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import PaginationCustom from '../../../components/Common/PaginationCustom/PaginationCustom';
import fetchPlotRequestBuildParams from '../../../lib/fetchPlotRequestBuildParams';
import plotActions from '../../../redux/plot/actions';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { addrServProcessAsync } from '../../../utils/addrServProcessAsync';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import { loadersActions } from '../../loaders/loaderSlice';
import MultiPlotOwnerBtn from '../../plot/multiPlotSelectFeature/components/buttons/MultiPlotOwnerBtn';
import { getMultiPlotSelectState } from '../../plot/multiPlotSelectFeature/multiPlotSelectSlice';
import { getDashboardOwnerDatasState } from '../dashboardOwnerDatasSlice';
import { fetchOwnerPlotFromSirenThunk } from '../dashboardOwnerDatasThunk';
import { dataOwnerPlotStudiesHeaderCell } from '../data';
import CheckboxForAdd from './CheckboxForAdd';

function PlotStudiesTable() {
  const { plotsFromSiren, ownerInfos, siren } = useAppSelector(
    getDashboardOwnerDatasState
  );

  const navigate = useNavigate();
  const { ownerPlots } = useAppSelector(getMultiPlotSelectState);
  const dispatch = useAppDispatch();

  const plotResult = plotsFromSiren.result;

  const handlePlotIdClick = async (plot: OwnerDataPlot) => {
    const { lat, lng } = plot;

    const addr = await addrServProcessAsync(lat, lng);

    const request = fetchPlotRequestBuildParams({ data: addr, studyParams: null });

    if (request) {
      dispatch(plotActions.fetchPlot.request(request));
    } else {
      console.log('bad data');
    }
    dispatch(loadersActions.loaderShow());
    delay(() => {
      dispatch(loadersActions.loaderHide());

      navigate('/');
    }, 2000);
  };

  // ***** Changing items per page action *****
  const handleItemsPerPageChange = (e: ChangeEventCustom) => {
    const value: number = e.target.value;
    const p = Math.floor(plotResult?.page ?? 1 / value) + 1;

    dispatch(
      fetchOwnerPlotFromSirenThunk({
        siren,
        page: p,
        itemsPerPage: value,
        sort: plotResult?.sort ?? null,
        order: plotResult?.order ?? null,
      })
    );
  };

  // ***** Changing page action *****
  const handleChangeIndex = (index: number): void => {
    const ipp = plotResult?.itemsPerPage || 15;
    const p = Math.floor(index / ipp) + 1;

    if (plotResult?.page && p !== plotResult?.page) {
      dispatch(
        fetchOwnerPlotFromSirenThunk({
          siren,
          page: p,
          itemsPerPage: ipp,
          sort: plotResult?.sort ?? null,
          order: plotResult?.order ?? null,
        })
      );
    }
  };

  // Loading datas
  if (plotsFromSiren.apiStatus === APIStatus.PENDING) {
    return <IndividualCircularLoader size={150} />;
  }

  return (
    <>
      <div className="folder-add-block">
        <MultiPlotOwnerBtn />
      </div>

      <TableContainer className="plot-studies-table">
        <Table>
          <TableHead>
            <TableRow>
              {dataOwnerPlotStudiesHeaderCell.map((elt) => (
                <TableCell key={nanoid()}>{elt.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {plotsFromSiren.apiStatus === APIStatus.FULFILLED &&
            !plotResult?.plots &&
            siren ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <IndividualCircularLoader
                    size={100}
                    className="plot-studies-loader"
                  />
                </TableCell>
              </TableRow>
            ) : plotResult?.plots?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6}>Aucune parcelle trouvée</TableCell>
              </TableRow>
            ) : (
              plotResult?.plots?.map((f) => (
                <TableRow key={nanoid()} className="row">
                  <TableCell>
                    <CheckboxForAdd
                      plot={f}
                      checked={Boolean(
                        ownerPlots?.find((o) => o.fullPlotId === f.fullPlotId)
                      )}
                    />
                  </TableCell>
                  <TableCell>{f.city}</TableCell>
                  <TableCell
                    onClick={() => handlePlotIdClick(f)}
                    className="clickable"
                    title="Voir la parcelle sur la map"
                  >
                    {f.fullPlotId.substring(5)}
                  </TableCell>
                  <TableCell>{`${
                    f.theoricCapacity
                      ? parseFloat(f.theoricCapacity.toFixed(2))
                      : '-'
                  } m²`}</TableCell>
                  <TableCell>{f.propertyType}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationCustom
        itemPerPageLabel="Parcelles par page"
        elementsQty={ownerInfos?.result?.plotCount ?? 0}
        itemsPerPage={plotResult?.itemsPerPage ?? 15}
        page={plotResult?.page ?? 1}
        onItemsPerPageChange={(e: ChangeEventCustom) => handleItemsPerPageChange(e)}
        onMinIndexTab={(index) => handleChangeIndex(index)}
      />
    </>
  );
}

export default memo(PlotStudiesTable);
