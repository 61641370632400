import * as yup from 'yup';

export const modelTypesForRadioBtns: ValueDisplaysType = [
  { value: 'plot', display: 'Parcelle unique' },
  { value: 'subfolder', display: 'Parcelles multiples' },
];
const modelTypes = modelTypesForRadioBtns.map(
  (m) => m.value as string
) as MailshotType[];

export const renameMailshotFormSchema = yup.object({
  name: yup.string().required('Le nom du modèle est requis'),
  isActive: yup.boolean().default(true),
  modelType: yup
    .string()
    .oneOf(modelTypes, 'Le type de modèle est requis')
    .required('Le type du modèle est obligatoire'),
});
