import { createContext, useState } from 'react';

export enum ProspectionDisplayEnum {
  sectors = 'Secteurs',
  folders = 'Dossiers',
}

export enum searchTypeEnum {
  Adresse = 'Adresse',
  Parcelle = 'Parcelle',
}

export enum predictionOriginEnum {
  GOOGLE = 'GOOGLE',
  ADDRESS_SERVER = 'ADDRESS_SERVER',
  OSM = 'OSM',
}

export enum PredictionTypeEnum {
  MUNICIPALITY = '0-Municipalité',
  LOCALITY = '1-Lieu dit',
  STREET = '2-Rue',
  TOURIST_ATTRACTION = '3-Attraction touristique',
  ARCHEOLOGICAL_SITE = '4-site archéologique',
  OTHERS = '5-Autre',
}

type UpdateStringValue = React.Dispatch<React.SetStateAction<string>>;
type UpdateNumberValue = React.Dispatch<React.SetStateAction<number>>;
type UpdatePredictions = React.Dispatch<React.SetStateAction<PredictionBase>>;

type UpdateBoolean = React.Dispatch<React.SetStateAction<boolean>>;
type UpdateInputTab = React.Dispatch<React.SetStateAction<searchTypeEnum>>;
type UpdateProspectionTab = React.Dispatch<
  React.SetStateAction<ProspectionDisplayEnum>
>;
type UpdateMenuTab = React.Dispatch<React.SetStateAction<string>>;
type VoidFunction = () => void;

export default function createInputTabsCtx() {
  const defaultInputTab = searchTypeEnum.Adresse;
  const defaultProspectionTab = ProspectionDisplayEnum.sectors;
  const defaultMenuTab = 'URBANISME';
  const defaultPlotId = '';
  const defaultInputValue = '';
  const defaultPredictions: PredictionBase = {
    origin: predictionOriginEnum.ADDRESS_SERVER,
    data: [],
  };
  const defaultPredictionDisplay = false;
  const defaultIsEnterClick = false;
  const defaultUrbanismeSelectedCategory = 1;
  const defaultEnvironmentSelectedCategory = 1;
  const defaultVoidFunction: VoidFunction = () => {};
  const setInputTab: UpdateInputTab = () => defaultInputTab;
  const setProspectionTab: UpdateProspectionTab = () => defaultProspectionTab;
  const setMenuTab: UpdateMenuTab = () => defaultMenuTab;
  const setPlotId: UpdateStringValue = () => defaultPlotId;
  const setInputValue: UpdateStringValue = () => defaultInputValue;
  const setPredictions: UpdatePredictions = () => defaultPredictions;
  const setPredictionsDisplay: UpdateBoolean = () => defaultPredictionDisplay;
  const setIsEnterClick: UpdateBoolean = () => defaultIsEnterClick;
  const setUrbanismeSelectedCategory: UpdateNumberValue = () =>
    defaultPredictionDisplay;
  const setEnvironmentSelectedCategory: UpdateNumberValue = () =>
    defaultIsEnterClick;

  const ctx = createContext({
    inputTab: defaultInputTab,
    setInputTab,
    prospectionTab: defaultProspectionTab,
    setProspectionTab,
    menuTab: defaultMenuTab,
    setMenuTab,
    plotId: defaultPlotId,
    setPlotId,
    inputValue: defaultInputValue,
    setInputValue,
    predictions: defaultPredictions,
    setPredictions,
    predictionsDisplay: defaultPredictionDisplay,
    setPredictionsDisplay,
    isEnterClick: defaultIsEnterClick,
    setIsEnterClick,
    urbanismeSelectedCategory: defaultUrbanismeSelectedCategory,
    setUrbanismeSelectedCategory,
    environmentSelectedCategory: defaultEnvironmentSelectedCategory,
    setEnvironmentSelectedCategory,
    resetContext: defaultVoidFunction,
  });

  const Provider = (props: React.PropsWithChildren<{}>) => {
    const [inputTab, setInputTab] = useState(searchTypeEnum.Adresse);
    const [prospectionTab, setProspectionTab] =
      useState<ProspectionDisplayEnum>(defaultProspectionTab);
    const [menuTab, setMenuTab] = useState(defaultMenuTab);
    const [plotId, setPlotId] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [predictions, setPredictions] =
      useState<PredictionBase>(defaultPredictions);
    const [predictionsDisplay, setPredictionsDisplay] = useState<boolean>(false);
    const [isEnterClick, setIsEnterClick] = useState<boolean>(false);
    const [urbanismeSelectedCategory, setUrbanismeSelectedCategory] =
      useState<number>(defaultUrbanismeSelectedCategory);
    const [environmentSelectedCategory, setEnvironmentSelectedCategory] =
      useState<number>(defaultEnvironmentSelectedCategory);

    const resetContext = () => {
      setInputTab(searchTypeEnum.Adresse);
      setMenuTab(defaultMenuTab);
      setPlotId('');
      setInputValue('');
      setPredictions(defaultPredictions);
      setPredictionsDisplay(false);
      setIsEnterClick(false);
      setUrbanismeSelectedCategory(defaultUrbanismeSelectedCategory);
      setEnvironmentSelectedCategory(defaultEnvironmentSelectedCategory);
    };

    return (
      <ctx.Provider
        value={{
          inputTab,
          setInputTab,
          prospectionTab,
          setProspectionTab,
          menuTab,
          setMenuTab,
          plotId,
          setPlotId,
          inputValue,
          setInputValue,
          predictions,
          setPredictions,
          predictionsDisplay,
          setPredictionsDisplay,
          isEnterClick,
          setIsEnterClick,
          urbanismeSelectedCategory,
          setUrbanismeSelectedCategory,
          environmentSelectedCategory,
          setEnvironmentSelectedCategory,
          resetContext,
        }}
        {...props}
      />
    );
  };

  return [ctx, Provider] as const;
}
