import { ChangeEvent } from 'react';
import { appActions, getAppState } from '../../../App/appSlice';
import { predictionOriginEnum } from '../../../App/contexts/InputTabsContext';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import RadioBtnCustom from '../../../shared/components/formComponents/RadioBtnCustom';
import styles from './inputTabs.module.scss';
const radioBtnValues: ValueDisplaysType = [
  { value: predictionOriginEnum.GOOGLE, display: 'Google' },
  { value: predictionOriginEnum.ADDRESS_SERVER, display: 'Serveur urba' },
  { value: predictionOriginEnum.OSM, display: 'OSM' },
];

function OriginRadioBtn() {
  const { autocompleteType } = useAppSelector(getAppState);
  const dispatch = useAppDispatch();
  const handleChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(
      appActions.setAutocompleteType(
        predictionOriginEnum[value as predictionOriginEnum]
      )
    );
  };

  return (
    <RadioBtnCustom
      radioBtnValues={radioBtnValues}
      value={autocompleteType}
      onChange={handleChange}
      className={styles.originRadioBtnContainer}
    />
  );
}

export default OriginRadioBtn;
