import { isArray } from 'lodash';
import {
  predictionOriginEnum,
  PredictionTypeEnum,
} from '../../App/contexts/InputTabsContext';
import axiosInstance from '../../services/AxiosPublicInstance';

const BASE_URL = process.env.REACT_APP_OSM_SERVER_URL;

export default (() => {
  const predictionParser = (data: any): Prediction => {
    const p = data.properties;
    let type: PredictionTypeEnum;

    switch (p?.osm_value) {
      case 'street':
      case 'housenumber':
      case 'house':
      case 'primary':
      case 'tertiary':
        type = PredictionTypeEnum.STREET;
        break;
      case 'village':
      case 'service':
        type = PredictionTypeEnum.LOCALITY;
        break;
      case 'archaeological_site':
        type = PredictionTypeEnum.ARCHEOLOGICAL_SITE;
        break;
      case 'town':
      default:
        type = PredictionTypeEnum.MUNICIPALITY;
        break;
    }
    console.log(p.name);
    const parsed: Prediction = {
      coordinates: [data.geometry.coordinates[1], data.geometry.coordinates[0]],
      type,
      id: p.osm_id,
      city: p.city ?? '',
      label: p.name
        ? p.name
        : p.street
          ? `${p.housenumber} ${p.street} (${p.city})`
          : '',
    };

    return parsed;
  };
  const predictionsParser = (datas: any) => {
    const valuesToKeep = [
      'town',
      'street',
      'village',
      'archaeological_site',
      'house',
      'tertiary',
      'primary',
      'service',
    ];
    const filtered = datas.filter((f: any) =>
      valuesToKeep.includes(f.properties?.osm_value)
    );
    return filtered.map((m: any) => predictionParser(m));
  };

  const getPredictions = (search: string, options: OsmAutocompleteOptions) => {
    return new Promise<PredictionBase>((resolve, reject) => {
      const predictionBase: PredictionBase = {
        origin: predictionOriginEnum.OSM,
        data: [],
      };
      type Key = keyof OsmAutocompleteOptions;

      const constructQuery = () => {
        let queryString = `q=${search}&osm_tag=!boundary&lang=fr&`;
        for (let key in options) {
          if (isArray(options[key as Key])) {
            (options[key as Key] as string[]).forEach((e) => {
              queryString += `${key}=${encodeURIComponent(e)}&`;
            });
          } else {
            queryString += `${key}=${encodeURIComponent(options[key as Key].toString())}&`;
          }
        }
        return queryString.slice(0, -1);
      };

      return axiosInstance()
        .get(`${BASE_URL}/api/?${constructQuery()}`, {})
        .then((res) => {
          const parsed = predictionsParser(res.data.features);

          predictionBase.data = parsed;

          return resolve(predictionBase);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const getLocationByLatLngAsync = (lat: number, lng: number) => {};
  return { getPredictions, getLocationByLatLngAsync };
})();
