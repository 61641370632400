interface IFetchPlotRequestBuildParamsProps {
  data: GeolocPointInfo | null;
  studyParams: StudyParamsState | null;
  zoom?: number;
  isAutocomplete?: boolean;
}

export default function fetchPlotRequestBuildParams({
  data,
  studyParams = null,
  zoom,
  isAutocomplete = false,
}: IFetchPlotRequestBuildParamsProps) {
  if (data?.department && data?.coordinates) {
    const coords = data.coordinates as number[];
    const request: IFetchPlotRequest = {
      data: {
        department: data.department,
        lat: coords[0],
        lon: coords[1],
        zoom,
        townHall: isAutocomplete,
      },
      plotLocation: data,
      studyParams,
      // isMultiPlotSelector,
    };

    return request;
  } else {
    return null;
  }
}
