import { isEmpty } from 'lodash';
import { useContext, useEffect } from 'react';
import { InputTabsContext } from '../../App/Layout';
import { getAppState } from '../../App/appSlice';
import {
  predictionOriginEnum,
  searchTypeEnum,
} from '../../App/contexts/InputTabsContext';
import useHistoricCookie from '../../App/hooks/useHistoricCookie';
import { useAppDispatch, useAppSelector } from '../../App/store';
import fetchPlotRequestBuildParams from '../../lib/fetchPlotRequestBuildParams';
import plotActions from '../../redux/plot/actions';
import GoogleService from '../../shared/services/GoogleService';
import genericSort from '../../utils/genericSort';
import { plotServitudesActions } from '../plotServitudes/plotServitudesSlice';

import { nanoid } from '@reduxjs/toolkit';
import { GenericModalEnum } from '../../components/Modals/GenericModal';
import { addrServProcessAsync } from '../../utils/addrServProcessAsync';
import { loadersActions } from '../loaders/loaderSlice';
import { getMapState, mapActions } from '../map/mapSlice';
import { modalsActions } from '../modals/modalsSlice';
import PredictionRow from './PredictionRow';
import styles from './autocomplete.module.scss';

interface IPredictionContainerProps {
  resetSessionToken: () => void;
  sessionToken: string | null;
}

function PredictionsContainer({
  resetSessionToken,
  sessionToken,
}: IPredictionContainerProps) {
  const { setCookie } = useHistoricCookie();
  const { activateFavorite, autocompleteType } = useAppSelector(getAppState);
  const { geolocDatas } = useAppSelector(getMapState);
  const {
    inputValue,
    predictionsDisplay,
    predictions,
    setInputValue,
    setPredictions,
    setPredictionsDisplay,
    isEnterClick,
    setIsEnterClick,
    inputTab,
  } = useContext(InputTabsContext);

  const sorted: Prediction[] = genericSort(predictions.data, 'asc', 'type');

  const dispatch = useAppDispatch();

  const googleClickProcess = async (prediction?: Prediction) => {
    try {
      if (!prediction) throw new Error('no prediction found');

      setPredictionsDisplay(false);
      setIsEnterClick(false);
      setInputValue(prediction.label);
      const predictionDetails = await GoogleService.getPredictionDetails(
        prediction.id,
        sessionToken
      );

      if (predictionDetails) {
        resetSessionToken();

        return {
          lat: predictionDetails.geometry[0],
          lng: predictionDetails.geometry[1],
        };
      } else {
        resetSessionToken();
        return null;
      }
    } catch (error) {
      resetSessionToken();
      return null;
    }
  };

  const resultTraitment = (
    prediction: Prediction,
    response: GeolocPointInfo | null
  ) => {
    if (response) {
      if (response?.inseeCode && response?.inseeCode !== geolocDatas?.inseeCode) {
        dispatch({ type: 'app/changeInsee' });
      }

      // favorite places (not used)
      if (activateFavorite) {
        setCookie(
          response,
          prediction,
          Boolean(inputTab === searchTypeEnum.Parcelle)
        );
      }

      setInputValue(response.address);
      const dataRequest = fetchPlotRequestBuildParams({
        data: response,
        studyParams: null,
      });

      dispatch(plotServitudesActions.reset());
      if (response.inseeCode) {
        dispatch(mapActions.geolocSet(response));
      }

      if (dataRequest) {
        dispatch(plotActions.fetchPlot.request(dataRequest));
      } else {
        console.log('error data');
      }
    } else {
      dispatch(
        modalsActions.alert({
          status: true,
          context: 'noPlotFounded',
          modalType: GenericModalEnum.INFO,
        })
      );
    }
  };

  const handlePredictionClick = async (prediction?: Prediction) => {
    try {
      let tempPrediction: Prediction | undefined = prediction;
      let coordinates: CoordinatesType | null = null;

      // if enter with keyboard
      if (!prediction && !isEmpty(predictions.data)) {
        tempPrediction = predictions.data[0];
      }

      coordinates = tempPrediction?.coordinates
        ? {
            lat: tempPrediction?.coordinates[0],
            lng: tempPrediction?.coordinates[1],
          }
        : null;

      if (autocompleteType === predictionOriginEnum.GOOGLE) {
        coordinates = await googleClickProcess(tempPrediction);
      }

      if (coordinates) {
        dispatch(
          mapActions.mapCenterSet({
            latLng: [coordinates.lat, coordinates.lng],
            zoom: 17,
          })
        );
        // add input loader before fetch address
        dispatch(loadersActions.addressLoaderSet(true));
        // fetch address server OR googlefor address plot
        const result = await addrServProcessAsync(coordinates.lat, coordinates.lng);
        // remove input loader address is fetched
        dispatch(loadersActions.addressLoaderSet(false));

        if (result) {
          resultTraitment(tempPrediction as Prediction, result);
        }

        setPredictions({
          origin: predictionOriginEnum.ADDRESS_SERVER,
          data: [],
        });
      } else {
        throw new Error('no coordinates found');
      }
    } catch (error) {
      // remove input loader if error
      dispatch(loadersActions.addressLoaderSet(false));

      // open error modal alert
      dispatch(
        modalsActions.alert({
          status: true,
          context: 'noPlotFounded',
          modalType: GenericModalEnum.INFO,
        })
      );
    }
  };

  useEffect(() => {
    if (isEnterClick) {
      handlePredictionClick();
    }
  }, [isEnterClick]);

  return (
    <div
      className={`${styles.predictionsContainer} ${
        predictionsDisplay ? styles.visible : ''
      }`}
    >
      <ul>
        {isEmpty(predictions) && inputValue ? (
          <li>Aucune correspondance</li>
        ) : (
          <li className={styles.predictionList}>
            {sorted?.map((row, index) => (
              <PredictionRow
                key={nanoid()}
                prediction={row}
                lastType={index === 0 ? null : sorted[index - 1].type}
                index={index}
                onPredictionClick={handlePredictionClick}
              />
            ))}
          </li>
        )}
      </ul>
    </div>
  );
}

export default PredictionsContainer;
