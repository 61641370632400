import * as protomaps from 'protomaps';
import { memo, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useAppSelector } from '../../../App/store';
import usePLU from '../../../shared/hooks/usePLU';
import { getMapState } from '../../map/mapSlice';
import { MyPaintSymbolizerPluOther } from './methods';
import { PolygonLabelSymbolizer } from './symbolizer/symbolizer';

function PmTilesPluOther() {
  const map = useMap();
  const { pluOthersPmtilesUrl, pluOthers } = usePLU();
  const { geolocDatas } = useAppSelector(getMapState);

  const resetLayer = () => {
    map.eachLayer((l: any) => {
      if (l.options.id === 'plu_others') {
        map.removeLayer(l);
      }
    });
  };

  const getAttributeValue = (elt: any, attributeName: string): any => {
    const attribute = elt.attributes.find(
      (attr: any) => attr.attribute === attributeName
    );
    return attribute?.values[0] ?? null;
  };

  useEffect(() => {
    if (geolocDatas?.inseeCode && pluOthersPmtilesUrl && pluOthers) {
      // reset layer if a city is already displayed
      resetLayer();

      const p = new protomaps.PMTiles(pluOthersPmtilesUrl);

      p.getMetadata().then((m: any) => {
        m.tilestats.layers.map(
          (elt: any) => {
            const attributeLibelle = getAttributeValue(elt, 'libelle');
            const pluOthersFlat = pluOthers
              .map((elt) => elt.childs)
              .flat(1)
              .map((elt) => elt.childs)
              .flat(1);

            if (
              pluOthersFlat.find(
                (item) => item?.displayed && item.libelle === attributeLibelle
              )
            ) {
              const layer = protomaps.leafletLayer({
                paint_rules: [
                  {
                    dataLayer: elt.layer,
                    symbolizer: new MyPaintSymbolizerPluOther(),
                    minzoom: 0,
                    maxzoom: 22,
                  },
                ],
                label_rules: [
                  {
                    dataLayer: elt.layer,
                    symbolizer: new PolygonLabelSymbolizer({
                      label_props: (zoom: any, feature: any) => {
                        return ['libelle'];
                      },
                      fill: 'white',
                      width: 2,
                      stroke: 'red',
                      font: '600 14px sans-serif',
                      lineHeight: 1.2,
                    }),
                    minzoom: 15,
                    maxzoom: 22,
                  },
                ],

                attribution: '',
                url: p.source.getKey(),
              });

              layer.options.id = 'plu_others';
              layer.options.zIndex = 3;
              layer.options.opacity = 0.7;
              layer.addTo(map);
            }
          },
          (err: any) => console.log('err', err)
        );
      });
    } else {
      // reset layer if no insee code
      resetLayer();
    }
  }, [geolocDatas?.inseeCode, pluOthers]);

  return null;
}

export default memo(PmTilesPluOther);
