import { useAppSelector } from '../../App/store';
import { getCompanyState } from '../../features/company/companySlice';

const COMPANY_DEVELOPMENT_USED = 7;

function useHideDevelopmentFeature() {
  const { companyId } = useAppSelector(getCompanyState);

  return { devDisplay: companyId === COMPANY_DEVELOPMENT_USED };
}

export default useHideDevelopmentFeature;
